import AbstractView from '../../framework/view/abstract-view';
import { debounce } from '../modules/utils/debounce';

const createButtonFormTemplate = (modClass, text) => {
  return (`
    <button data-open-modal="first" class="button ${modClass ? `button--${modClass}` : ""}" type="button">
      <span>
        ${text}
      </span>
      <svg width="21" height="14" viewBox="0 0 21 14" fill="none" fill="CurrentColor">
        <path d="M0.522705 6.99609H19.5227M19.5227 6.99609L13.5227 0.996094M19.5227 6.99609L13.5227 12.9961" stroke="white" stroke-width="2" />
      </svg>
    </button>
  `);
};

export default class ButtonOpenFormView extends AbstractView {
  #modClass = null;
  #text = null;
  constructor(modClass = null, text = "написать&nbsp;мне") {
    super();
    this.#modClass = modClass;
    this.#text = text;
  }

  get template() {
    return createButtonFormTemplate(this.#modClass, this.#text);
  }

  setClickCloseOpenForm = () => {
    this.element.addEventListener('click', this.#debouncedСlickOpenFormHandler);
  };

  #debouncedСlickOpenFormHandler = () => debounce(this.#clickOpenFormHandler, 2000);

  #clickOpenFormHandler = (evt) => {
    evt.preventDefault();
    console.log("debounce");
  };
}
