import AbstractStatefulView from '../../framework/view/abstract-stateful-view.js';
import {FocusLock} from '../../js/modules/utils/focus-lock';
import {disablePageScroll, enablePageScroll} from '../../js/modules/utils/scroll-lock';

const createPopupTemplate = (spam) => {
  return (`
    <div class="modal" data-modal="first" data-modal-name="first">
      <div class="modal__wrapper">
        <div class="modal__overlay" data-close-modal></div>
        <div class="modal__content">
          <p class="modal__text modal__text--success">Ваш запрос<span> отправлен </span> </p>
          <p class="modal__text modal__text--error"></p>
          <form action="#" method="post" enctype="multipart/form-data" method="POST">
            <div class="modal__item">
              <label class="visually-hidden" for="appointment-name">Ваше имя:</label>
              <input class="modal__input" id="appointment-name" type="text" value="" name="name" placeholder="Имя" required autocomplete="off">
            </div>
            <div class="modal__item">
              <label class="visually-hidden" for="appointment-mail">Ваш e-mail</label>
              <input class="modal__input" id="appointment-mail" type="email" value="" name="mail" placeholder="user@yandex.ru" required autocomplete="off">
            </div>
            <div class="modal__item">
              <input class="modal__input modal__input--check-spam" id="check-spam" type="hidden" value="${spam.spam}" name="check-spam" autocomplete="off">
            </div>
            <div class="modal__item">
              <label class="visually-hidden" for="appointment-letter">Текст письма:</label>
              <textarea class="modal__input modal__input--area" id="appointment-letter" name="letter" placeholder="В свободной форме" autocomplete="off"></textarea>
            </div>
            <div class="modal__item modal__item--check">
              <label class="modal__check-lab" for="check">
              <input class="modal__check" id="check" type="checkbox" value="" name="check" required>
              Отправляя это письмо, вы соглашаетесь на обработку персональных данных</label>
            </div>
            <input data-submit class="modal__submit" type="submit" name="submit" value="Отправить">
          </form>
          <button data-close-modal class="modal__exit" aria-label="Кнопка для закрытия формы" type="button"><img src="img/close.svg" width="21" height="21" alt="закрыть"></button>
        </div>
      </div>
    </div>
  `);
};

export default class PopupView extends AbstractStatefulView {
  #isSpam = {spam: "spam", action: "#"};

  constructor(settings = {}) {
    super();
    this._state = this.#isSpam;
    this._scrollLock = {
      disablePageScroll,
      enablePageScroll,
    };
    this._focusLock = new FocusLock();

    this._modalOpenElements = document.querySelectorAll('[data-open-modal]');
    this._openedModalElement = null;
    this._modalName = null;
    this._enableScrolling = true;
    this._settingKey = 'default';

    this._settings = settings;
    this._preventDefault = this._settings[this._settingKey].preventDefault;
    this._lockFocus = this._settings[this._settingKey].lockFocus;
    this._startFocus = this._settings[this._settingKey].startFocus;
    this._focusBack = this._settings[this._settingKey].focusBack;
    this._eventTimeout = this._settings[this._settingKey].eventTimeout;
    this._openCallback = this._settings[this._settingKey].openCallback;
    this._closeCallback = this._settings[this._settingKey].closeCallback;

    this._documentKeydownHandler = this._documentKeydownHandler.bind(this);
    this._documentClickHandler = this._documentClickHandler.bind(this);
    this._modalClickHandler = this._modalClickHandler.bind(this);
    this._submitHandler = this._submitHandler.bind(this);

    this._init();
  }

  _init() {
    if (this._modalOpenElements.length) {
      document.addEventListener('click', this._documentClickHandler);
    }
  }

  get template() {
    return createPopupTemplate(this._state);
  }

  _setSettings(settingKey = this._settingKey) {
    if (!this._settings[settingKey]) {
      return;
    }

    this._preventDefault =
      typeof this._settings[settingKey].preventDefault === 'boolean'
        ? this._settings[settingKey].preventDefault
        : this._settings[this._settingKey].preventDefault;
    this._stopPlay =
      typeof this._settings[settingKey].stopPlay === 'boolean'
        ? this._settings[settingKey].stopPlay
        : this._settings[this._settingKey].stopPlay;
    this._lockFocus =
      typeof this._settings[settingKey].lockFocus === 'boolean'
        ? this._settings[settingKey].lockFocus
        : this._settings[this._settingKey].lockFocus;
    this._startFocus =
      typeof this._settings[settingKey].startFocus === 'boolean'
        ? this._settings[settingKey].startFocus
        : this._settings[this._settingKey].startFocus;
    this._focusBack =
      typeof this._settings[settingKey].lockFocus === 'boolean'
        ? this._settings[settingKey].focusBack
        : this._settings[this._settingKey].focusBack;
    this._eventTimeout =
      typeof this._settings[settingKey].eventTimeout === 'number'
        ? this._settings[settingKey].eventTimeout
        : this._settings[this._settingKey].eventTimeout;
    this._openCallback = this._settings[settingKey].openCallback || this._settings[this._settingKey].openCallback;
    this._closeCallback = this._settings[settingKey].closeCallback || this._settings[this._settingKey].closeCallback;
  }

  _documentClickHandler(evt) {
    const target = evt.target;
    if (!target.closest('[data-open-modal]')) {return;}
    evt.preventDefault();

    this._modalName = target.closest('[data-open-modal]').dataset.openModal;

    if (!this._modalName) {return;}
    this.open();
  }

  _documentKeydownHandler(evt) {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

    if (isEscKey) {
      evt.preventDefault();
      this.close(document.querySelector('.modal.is-active').dataset.modal);
    }
  }

  _modalClickHandler(evt) {
    const target = evt.target;
    if (!target.closest('[data-close-modal]')) {return;}
    this.close(target.closest('[data-modal]').dataset.modal);
  }

  _addListeners(modal) {
    modal.addEventListener('click', this._modalClickHandler);
    modal.querySelector('form').addEventListener('submit', this._submitHandler);
    document.addEventListener('keydown', this._documentKeydownHandler);
  }

  _removeListeners(modal) {
    modal.removeEventListener('click', this._modalClickHandler);
    modal.querySelector('form').removeEventListener('submit', this._submitHandler);
    document.removeEventListener('keydown', this._documentKeydownHandler);
  }

  open(modalName = this._modalName) {
    const modal = document.querySelector(`[data-modal="${modalName}"]`);

    if (localStorage.getItem("mail")) {modal.querySelector('[name="mail"]').value = JSON.parse(localStorage.getItem("mail"));}
    if (!modal || modal.classList.contains('is-active')) {return;}

    document.removeEventListener('click', this._documentClickHandler);

    this._openedModalElement = document.querySelector('.modal.is-active');

    if (this._openedModalElement) {
      this._enableScrolling = false;
      this._scrollLock.enablePageScroll(this._openedModalElement);
      this._scrollLock.disablePageScroll(modal);
      this.close(this._openedModalElement.dataset.modal);
    }

    this._setSettings(modalName);
    modal.classList.add('is-active');

    if (!this._openedModalElement) {
      this._scrollLock.disablePageScroll(modal);
    }

    if (this._openCallback) {
      this._openCallback();
    }

    if (this._lockFocus) {
      this._focusLock.lock('.modal.is-active', this._startFocus);
    }

    setTimeout(() => {
      this._addListeners(modal);
    }, this._eventTimeout);
  }

  close(modalName = this._modalName) {
    const modal = document.querySelector(`[data-modal="${modalName}"]`);

    if (!modal || !modal.classList.contains('is-active')) {return;}

    if (this._lockFocus) {
      this._focusLock.unlock(this._focusBack);
    }

    modal.classList.remove('is-active');
    this._removeListeners(modal);

    if (this._closeCallback) {
      this._closeCallback();
    }

    if (this._enableScrolling) {
      setTimeout(() => {
        this._scrollLock.enablePageScroll(modal);
      }, this._eventTimeout);
    }

    setTimeout(() => {
      document.addEventListener('click', this._documentClickHandler);
    }, this._eventTimeout);

    this._setSettings('default');
    this._enableScrolling = true;
  }

  _restoreHandlers = () => {
    this._init();
  };

  _submitHandler = (evt) => {
    const modalName = this._modalName
    const modal = document.querySelector(`[data-modal="${modalName}"]`);
    const form = modal.querySelector('form');
    const textSuccess = modal.querySelector('.modal__text--success');
    const textError = modal.querySelector('.modal__text--error');
    const self = this;
    evt.preventDefault();


    let URL = '../php/mail.php';
    let TIMEOUT_IN_MS = 7000;
    let StatusCode = {
      OK: 200,
    };

    let save = function (data) {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'json';

      xhr.addEventListener('load', function () {
        if (xhr.status === StatusCode.OK) {
          if (modal) {
            textSuccess.classList.add('modal__text--active');
            setTimeout(() => {
              self.close(self._modalName);
              setTimeout(() => {
                textSuccess.classList.remove('modal__text--active');
                form.reset();
              }, 1000);
            }, 1300);
          }
        } else {
          if (modal && form) {
            textError.classList.add('modal__text--active');
            textError.textContent = 'Произошла ошибка, попробуйте ещё раз';
            setTimeout(() => {
              self.close(self._modalName);
              setTimeout(() => {
                textError.classList.remove('modal__text--active');
                textError.textContent = '';
              }, 1000);
            }, 1300);
          }
        }
      });

      xhr.addEventListener('error', function () {
        textError.textContent = xhr.statusText;
      });

      xhr.addEventListener('timeout', function () {
        textError.classList.add('.modal__text--active');
        textError.textContent = `Запрос не успел выполниться за ' ${xhr.timeout} 'мс'`;

        setTimeout(() => {
          self.close(self._modalName);
          setTimeout(() => {
            textError.classList.remove('.modal__text--active');
            textError.textContent = '';
          }, 1000);
        }, 1300);
      });

      xhr.timeout = TIMEOUT_IN_MS;

      xhr.open('POST', URL);
      xhr.send(data);
    };

    // let formData = new FormData(form);
    // const data = Object.fromEntries(formData);
    save(new FormData(form));
  };
}
