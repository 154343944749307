const changeHeight = () => {
  const listAboatme = document.querySelector('.aboutme__list-left');

  if (listAboatme) {

    // console.log(listAboatme);
    let listHeight = listAboatme.getBoundingClientRect().height;
    document.documentElement.style.setProperty('--listHeight', `${listHeight}px`);

    window.addEventListener('resize', function () {
      document.documentElement.style.setProperty('--listHeight', `${listHeight}px`);

    });
  }
};

export {changeHeight};
