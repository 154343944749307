import AbstractView from '../../framework/view/abstract-view';

const createPortfolioTemplate = (swipersData) => {

  const genetateSwiperElement = () => {

    return (swipersData ? `${swipersData.map((swiper) =>
      `<div class="swiper-slide swiper-slide--new">
      ${swiper.href ? `<a href="${swiper.href}">` : '<a class="swiper__no-hover">'}
        ${swiper.isHref ? '<span class="swiper__view">перейти на сайт</span>' : ''}
        <picture>
          <source type="img/webp" srcset="img/${swiper.webp} 1x, img/${swiper.webp2x} 2x">
          <img src="img/${swiper.img}" srcset="img/${swiper.img2x} 2x" width="${swiper.width}" height="${swiper.height}" alt="${swiper.alt}">
        </picture>
        <h3>${swiper.title}</h3>
      </a>
    </div>`).join('')}` : null);
  };

  return (`
    <div class="swiper" id="gal">

      <div class="swiper-container">
        <div class="swiper-wrapper">
          ${genetateSwiperElement()}
        </div>
        <div class="border-wrapper"></div>
        <div class="border-wrapper border-wrapper--right"></div>

        <div class="swiper-button-prev prev"></div>
        <div class="swiper-button-next next"></div>

        <div class="swiper-pagination"></div>
      </div>
    </div>
  `);
};

export default class PortfolioView extends AbstractView {
  #swipersData = null;

  constructor(swipersData) {
    super();
    this.#swipersData = swipersData;
  }

  get template() {
    return createPortfolioTemplate(this.#swipersData);
  }

  // setFilterTypeChangeHandler = (callback) => {
  //   this._callback.filterTypeChange = callback;
  //   this.element.addEventListener('click', this.#filterTypeChangeHandler);
  // };

  // #filterTypeChangeHandler = (evt) => {
  //   if (evt.target.getAttribute('data-disabled') === 'disabled') { return; }
  //   if (evt.target.tagName !== 'LABEL') { return; }
  //   evt.preventDefault();
  //   this._callback.filterTypeChange(evt.target.dataset.filter);
  // };
}
