import { iosInputScaleFix } from "./modules/utils/ios-input-scale-fix";
import { tab } from "./modules/tab";
import { changeHeight } from "./modules/change-height";
import { changePathname } from "./modules/change-pathname";
import { initSwiper } from "./modules/initswiper";
import { initMenu } from "./modules/init-menu";

// import { moveHeaderButton } from "./modules/move-header-button";

import Presenter from "./presenter/presenter";
import DevApiService from "./dev-api-service";
import DevModel from "./dev-model";

import barba from '@barba/core';
import gsap from "gsap";
import anime from "animejs";

let initScripts = () => {
  initMenu();
  tab();
  iosInputScaleFix();
  changePathname();
  changeHeight();
  initSwiper();
  // moveHeaderButton();
}

const mainFunction = () => {
  const END_POINT = '../db/db.json';
  const grayButtonPopupContainer = document.querySelectorAll('#item-1, .js-button-container');
  const popupContainer = document.querySelector('.modal-section');
  const swiperContainer = document.querySelector('.gallery--dev');
  const buttonPopupContainer = document.querySelector('.page-header__box');
  const model = new DevModel(new DevApiService(END_POINT));

  const presenter = new Presenter(
    model,
    swiperContainer,
    buttonPopupContainer,
    grayButtonPopupContainer,
    popupContainer
  );

  presenter.init();
}

  // window.addEventListener('DOMContentLoaded', () => {
  //   mainFunction();
  //   initScripts();
  // });


  function transitionIn (container) {
    let tl = gsap.timeline();
    let tl2 = gsap.timeline();
    const transLeft = container.querySelector('.trans--left');
    const transRight = container.querySelector('.trans--right');
    tl.to(transLeft, {
      scaleY: 1,
      duration: 1,
      ease: 'power3.out',
      transformOrigin: 'bottom',
    })
    tl2.to(transRight, {
      scaleY: 1,
      duration: 0.6,
      ease: 'power3.out',
      transformOrigin: 'top',
    })
    return tl;
  }

  function transitionEnter (container) {
    let tl = gsap.timeline();
    let tl2 = gsap.timeline();
    const transLeft = container.querySelector('.trans--left');
    const transRight = container.querySelector('.trans--right');
    tl.to(transLeft, {
      transformOrigin: 'top',
      delay: 0.1,
      scaleY: 0,
      duration: 1,
      ease: 'power3.out',
    })
    tl2.to(transRight, {
      transformOrigin: 'bottom',
      delay: 0.1,
      scaleY: 0,
      duration: 0.6,
      ease: 'power3.out',
    })
    return tl;
  }

  function delay (n) {
    n = n || 0
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, n);
    });
  }

  barba.hooks.afterEnter(() => {
    mainFunction();
    initScripts();
  });

  barba.hooks.beforeEnter((data) => {
    data.current.container.remove();
  });

  barba.init({
    sync: true,
    transitions: [{
      async leave(data) {
        const done = this.async();
        transitionIn(data.current.container)
        await delay(1000);
        done();
      },
      async beforeEnter(data) {
        const transLeft = data.next.container.querySelector('.trans--left');
        const transRight = data.next.container.querySelector('.trans--right');
        transRight && transRight.classList.add('trans--active');
        transLeft && transLeft.classList.add('trans--active');
      },
      async enter(data) {
        transitionEnter(data.next.container)
      },
    }]
  });
