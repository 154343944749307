const changePathname = () => {
  const links = document.querySelectorAll('.container__link, mywork__link');
  const regex = /.html/i;
  const pathname = window.location.pathname.replace(regex, '').slice(1);
  document.documentElement.style.setProperty('--pathname', `${pathname}`);
  document.documentElement.className = '';
  document.documentElement.classList.add(`js-active-${pathname}`);

  links && links.forEach((link) => {
    link.addEventListener('click', () => {
      const pathname = window.location.pathname.replace(regex, '').slice(1);
      document.documentElement.className = '';
      document.documentElement.classList.add(`js-active-${pathname}`);
      document.documentElement.style.setProperty('--pathname', `${pathname}`);
    });
  });
};

export {changePathname};
