import PortfolioView from '../view/portfolio-view';
import PopupView from '../view/popup-view';
import ButtonOpenFormView from '../view/button-open-form-view';

import { render, replace, remove, RenderPosition } from '../../framework/render';
import { initSwiper } from "../modules/initswiper";

export default class Presenter {
  #model = null;
  #portfolioDevViewComponent = null;
  #portfolioWebViewComponent = null;
  #portfolioPolViewComponent = null;

  #popupComponent = null;
  #popupButtonOpenFormComponent = null;
  #popupGrayButtonOpenFormComponent = null;
  #buttonPopupContainer = null;
  #grayButtonPopupContainers = null;
  #popupContainer = null;

  #swiperContainer = null;

  constructor (model, swiperContainer, buttonPopupContainer, grayButtonPopupContainers, popupContainer) {
    this.#model = model;

    this.#buttonPopupContainer = buttonPopupContainer;
    this.#grayButtonPopupContainers = grayButtonPopupContainers;
    this.#popupContainer = popupContainer;

    this.#swiperContainer = swiperContainer;
  }

  init = () => {
    this.#model.init()
      .then(() => {
        if (this.#buttonPopupContainer != null) {
          this.#renderButtonOpenForm();
        }
        if (this.#grayButtonPopupContainers != null) {
          this.#renderGrayButtonOpenForm();
        }
        if (this.#swiperContainer != null) {
          const containerName = this.#swiperContainer.dataset.name;
          if(containerName === "dev") {
            this.#renderSwiperPortfolioOfDevelopment();
          } else if (containerName === "web") {
            this.#renderSwiperPortfolioOfWebdesign();
          } else if (containerName === "pol") {
            this.#renderSwiperPortfolioOfPoligraph();
          }
        }
        this.#renderPopup();
        initSwiper();
      });
  };

  #renderSwiperPortfolioOfDevelopment = () => {
    this.#portfolioDevViewComponent = new PortfolioView(this.devSwiperData);
    render(this.#portfolioDevViewComponent, this.#swiperContainer, RenderPosition.BEFOREEND);
  };

  #renderSwiperPortfolioOfWebdesign = () => {
    this.#portfolioWebViewComponent = new PortfolioView(this.webSwiperData);
    render(this.#portfolioWebViewComponent, this.#swiperContainer, RenderPosition.BEFOREEND);
  };

  #renderSwiperPortfolioOfPoligraph = () => {
    this.#portfolioPolViewComponent = new PortfolioView(this.polSwiperData);
    render(this.#portfolioPolViewComponent, this.#swiperContainer, RenderPosition.BEFOREEND);
  };

  #renderPopup = () => {
    const settings = {
      'default': {
        preventDefault: true,
        lockFocus: true,
        startFocus: true,
        focusBack: true,
        eventTimeout: 400,
        openCallback: false,
        closeCallback: false,
      },
    };
    this.#popupComponent = new PopupView(settings);

    if (this.#popupContainer != null) {
      render(this.#popupComponent, this.#popupContainer, RenderPosition.BEFOREEND);
    }
  };

  #renderButtonOpenForm = () => {
    this.#popupButtonOpenFormComponent = new ButtonOpenFormView();
    render(this.#popupButtonOpenFormComponent, this.#buttonPopupContainer, RenderPosition.BEFOREEND);
    // this.#popupButtonOpenFormComponent.setClickCloseOpenForm();
  };

  #renderGrayButtonOpenForm = () => {
    this.#grayButtonPopupContainers.forEach((container, index) => {
      this.#popupGrayButtonOpenFormComponent = new ButtonOpenFormView("gray", `${index === 1 ? "написать" : "сделать заказ"}`);
      render(this.#popupGrayButtonOpenFormComponent, container, RenderPosition.BEFOREEND);
      this.#popupGrayButtonOpenFormComponent.setClickCloseOpenForm();
    });
  };

  get devSwiperData() {
    const devData = this.#model.devData;
    return devData;
  }

  get webSwiperData() {
    const wevData = this.#model.webData;
    return wevData;
  }

  get polSwiperData() {
    const polData = this.#model.polData;
    return polData;
  }
}
