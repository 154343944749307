/**
 * Класс для отправки запросов к серверу
 */
export default class ApiService {
  /**
   * @param {string} endPoint Адрес сервера
   * @param {string} authorization Авторизационный токен
   */
  // constructor(endPoint, authorization) {
  //   this._endPoint = endPoint;
  //   this._authorization = authorization;
  // }

  constructor(endPoint) {
    this._endPoint = endPoint;
  }

  /**
   * Метод для отправки запроса к серверу
   * @param {Object} config Объект с настройками
   * @param {string} config.url Адрес относительно сервера
   * @param {string} [config.method] Метод запроса
   * @param {string} [config.body] Тело запроса
   * @param {Headers} [config.headers] Заголовки запроса
   * @returns {Promise<Response>}
   */
  // _load = async ({
  //   method = 'GET',
  //   body = null,
  //   headers = new Headers(),
  // }) => {
  //   headers.append('Authorization', this._authorization);

  //   const response = await fetch(
  //     `${this._endPoint}`,
  //     {method, body, headers},
  //   );

  //   try {
  //     ApiService.checkStatus(response);
  //     return response;
  //   } catch (err) {
  //     ApiService.catchError(err);
  //   }
  // };

  _load = async () => {
    const response = await fetch(this._endPoint);
    try {
      ApiService.checkStatus(response);
      return response;
    } catch (err) {
      ApiService.catchError(err);
    }
  };

  /**
   * Метод для обработки ответа
   * @param {Response} response Объект ответа
   * @returns {Promise<JSON>}
   */
  static parseResponse = (response) => response.json();

  /**
   * Метод для проверки ответа
   * @param {Response} response Объект ответа
   */
  static checkStatus = (response) => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
  };

  /**
   * Метод для обработки ошибок
   * @param {Error} err Объект ошибки
   */
  static catchError = (err) => {
    throw err;
  };
}
