import {FocusLock} from './utils/focus-lock';
import {disablePageScroll, enablePageScroll} from './utils/scroll-lock';

const focusLock = new FocusLock();
const menu = document.querySelector('.menu');
const menuToggleBtn = menu ? menu.querySelector('[data-nav-toggle]') : null;
const mainNav = menu ? menu.querySelector('.menu__container') : null;

const spans = mainNav ? Array.from(mainNav.querySelectorAll('.menu__list span[tabindex]')) : null;
const mainMenuLinks = mainNav ? Array.from(mainNav.querySelectorAll('.menu__item > a, .menu__item > button')) : null;

const breakpoint = window.matchMedia('(max-width: 1023px)');

const openMenu = () => {
  if (!menu.classList.contains('is-opened')) {
    menuToggleBtn.setAttribute('aria-label', 'Закрыть меню');
    menuToggleBtn.setAttribute('aria-pressed', 'true');
    menu.classList.add('is-opened');
    mainNav.classList.add('is-visible');
    mainMenuLinks.forEach((item) => item.removeAttribute('tabindex'));

    document.addEventListener('keydown', onDocumentKeydown);
    disablePageScroll(mainNav);
  }
};

const closeMenu = () => {
  if (menu.classList.contains('is-opened')) {
    menuToggleBtn.setAttribute('aria-label', 'Открыть меню');
    menuToggleBtn.setAttribute('aria-pressed', 'false');
    mainMenuLinks.forEach((item) => item.setAttribute('tabindex', '-1'));

    mainNav.classList.remove('is-visible');
    setTimeout(() => {
      menu.classList.remove('is-opened');
    }, 100);

    document.removeEventListener('keydown', onDocumentKeydown);
    focusLock.unlock(true);
    enablePageScroll(mainNav);
  }
};

const onDocumentClick = (evt) => {
  // клик за пределами меню
  if (!evt.target.closest('.menu__container')) {
    closeMenu();
  }

  if (evt.target.closest('.menu__item a')) {
    setTimeout(() => {
      closeMenu();
    }, 100);
  }
  // кнопка закрытия
  if (breakpoint.matches && evt.target.closest('[data-nav-toggle]')) {
    if (menu.classList.contains('is-opened')) {
      menuToggleBtn.removeAttribute('data-focus');
      closeMenu();
    } else {
      menuToggleBtn.setAttribute('data-focus', 'true');
      openMenu();
    }
  }
};

const onDocumentKeydown = function (evt) {
  if (evt.key === 'Escape') {
    evt.preventDefault();
    closeMenu();
  }
};

const breakpointChecker = () => {
  // чтобы при смене брейка меню не моргало
  mainNav.style.display = 'none';
  setTimeout(() => {
    mainNav.style.display = null;
  }, 300);

  if (breakpoint.matches) {
    mainMenuLinks.forEach((item) => item.setAttribute('tabindex', '-1'));
    spans.forEach((item) => item.removeAttribute('tabindex'));
  }

  if (!breakpoint.matches) {
    closeMenu();
    mainMenuLinks.forEach((item) => item.removeAttribute('tabindex'));
    spans.forEach((item) => item.setAttribute('tabindex', '0'));
  }
};

const initMenu = () => {
  if (menu && mainNav) {
    document.addEventListener('click', onDocumentClick);
    breakpoint.addListener(breakpointChecker);

    if (breakpoint.matches) {
      spans.forEach((item) => item.removeAttribute('tabindex'));
      mainMenuLinks.forEach((item) => item.setAttribute('tabindex', '-1'));
    }
  }
};

export {initMenu};
