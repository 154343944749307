import Observable from '../framework/observable';

export default class DevModel extends Observable {
  #data = {};
  #devsApiService = null;

  constructor(url) {
    super();
    this.#devsApiService = url;
  }

  init = async () => {
    try {
      this.#data = await this.#devsApiService.dataSwiper;
    } catch(err) {
      this.#data = {};
    }
    // this._notify(UpdateType.INIT);
  };

  get devData() {
    return this.#data.development;
  }

  get webData() {
    return this.#data.webdesign;
  }

  get polData() {
    return this.#data.pol;
  }
}
