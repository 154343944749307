import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);
import '../../../node_modules/swiper/swiper-bundle.min';

export function initSwiper() {

  const swiperContainer = document.querySelector(".swiper-container");

  if (swiperContainer) {

    const swiper = new Swiper(swiperContainer, {
      modules: [Navigation, Pagination],
      grabCursor: true,
      speed: 600,
      effect: "creative",
      creativeEffect: {
        prev: {
          shadow: true,
          origin: "left center",
          translate: ["-5%", 0, -200],
          rotate: [0, 100, 0],
        },
        next: {
          origin: "right center",
          translate: ["5%", 0, -200],
          rotate: [0, -100, 0],
        },
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        // type: "progressbar",
      },
    });

    // const swiper = new Swiper(".swiper-container", {
    //   modules: [Navigation, Pagination],

    //   // initialSlide :0,
    //   // Observer: true,
    //   // Parents: true,
    //   // onSlideChangeEnd: function(swiper){
    //   //   swiper.update ();
    //   //   swiper.startAutoplay ();
    //   //   swiper.reLoop ();
    //   // },

    //   // spaceBetween: 30,
    //   slidesPerView: 1,
    //   // slidesPerGroup: 4,
    //   loopFillGroupWithBlank: true,
    //   breakpointsInverse: true,
    //   effect: "creative",
    //   creativeEffect: {
    //     prev: {
    //       shadow: true,
    //       translate: [0, 0, -400],
    //     },
    //     next: {
    //       translate: ["100%", 0, 0],
    //     },
    //   },
    //   breakpoints: {
    //     320: {
    //       pagination: {
    //         el: ".swiper-pagination",
    //         type: "custom",
    //         renderCustom: function (swiper, current, total) {
    //           return (current) + " of " + (total);
    //         }
    //       },
    //     },
    //     768: {

    //     },
    //     1024: {

    //     }
    //   },
    //   autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: true,
    //   },
    //   // pagination: {
    //   //   el: ".swiper-pagination",
    //   //   clickable: true,
    //   //   renderBullet: function (index, className) {
    //   //     return '<span class="' + className + '">' + (index + 1) + "</span>";
    //   //   },
    //   // },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     dynamicBullets: true,
    //     clickable: true,
    //   },
    // });
  }
}
