export function tab() {
  const tabs = document.querySelectorAll("[data-tab-target]");
  const tabContents = document.querySelectorAll("[data-tab-content]");

  const openElementAction = (element, evt) => {
    evt.preventDefault();
    const target = document.querySelector(element.dataset.tabTarget);
    tabContents.forEach(tabContent => tabContent.classList.remove("aboutme__item-left--active"));
    tabs.forEach(tab => tab.classList.remove("aboutme__item--active"));
    element.classList.add("aboutme__item--active");
    target.classList.add("aboutme__item-left--active");
  }

  tabs.forEach(tab => {
    tab.addEventListener("click", (evt) => {
      openElementAction(tab, evt);
    });

    tab.addEventListener('keydown', (evt) => {
      const isEnter = evt.key === 'Enter';
      const isTab = evt.target.matches("[data-tab-target]");
      if (isEnter && isTab) {
        openElementAction(tab, evt);
      }
    });
  });
}
